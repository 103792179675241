import {
  Box,
  Typography,
  // BoxProps,
  // Typography,
  // useMediaQuery,
  // useTheme,
  // MenuItem,
  // MenuItemProps,
} from "@mui/material";
import cn from "classnames";
import {
  // useLocation,
  NavLink,
} from "react-router-dom";

import { styled } from "@mui/material";
// import MenuItems from "./menu";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
// import {
//   ForumIcon,
//   DiscordIcon,
//   TwitterIcon,
//   TelegramIcon,
//   MediumIcon,
// } from "../../components/svg-icon";

import { useAppBreakpoints } from "hooks/useAppBreakpoints";
// import { type } from "../../state/index";
import SelectIcon from "../../assets/layout/select.svg";
import PusdLogo from "../../assets/layout/pusd-logo.svg";
import LongOnlyLogo from "../../assets/layout/longonly-logo.svg";

const Navs = (props: any) => {
  // let location = useLocation();
  // let path = location.pathname.replace("/", "");
  // const theme = useTheme();
  // const { isMatchMobile } = useAppBreakpoints();

  return (
    <Box className={cn("sm:pb-2.75")}>
      <div className="flex w-full sm:flex-grow items-center sm:items-start sm:justify-between sm:flex-col gap-x-1 sm:gap-x-0 sm:gap-y-2">
        {/* <NavItem to="/vision" title="Vision" /> */}
        <NavItem type="products" title="Products"></NavItem>
        <NavItem to="/launch" title="Launch" disabled={false} />
        {/* <NavItem to="/pusd" title="PUSD" />
        <NavItem
          to="https://longonly.pure.cash/"
          type="link"
          title="LongOnly"
          target="_self"
          disabled={false}
        /> */}
        <NavItem
          to="https://github.com/pure-cash/pure-cash-contracts/blob/main/audits/ABDK__Pure_Cash.pdf"
          type="link"
          title="Audits"
          target="_blank"
          disabled={false}
        />
        <NavItem
          to="https://docs.pure.cash/pure.cash"
          type="link"
          title="Docs"
          target="_blank"
          disabled={false}
        />
        {/* <NavItem to="#" type="link" title="Airdrop" disabled />
        <NavItem to="#" type="link" title="Perpetual" disabled />
        <NavItem to="#" type="link" title="Ecosystem" disabled /> */}
      </div>
    </Box>
  );
};

const NavItem = (props: any) => {
  const {
    to,
    title,
    type = "router",
    target = "",
    disabled = true,
    className = "",
  } = props;
  const { isMatchMobile } = useAppBreakpoints();
  if (type === "link") {
    return (
      <StyledALink
        href={to}
        target={target}
        className={`text-base ${
          disabled ? "disabled" : ""
        } ${className} sm:px-0`}
      >
        {title}
      </StyledALink>
    );
  }
  if (type === "products") {
    return (
      <Box className={`text-base cursor-default relative parent ${className}`}>
        <Box className="flex items-center space-x-[4px] item-box sm:px-0">
          <div className="title">{title}</div>
          <div className="w-[16px] sm:hidden">
            <img
              src={SelectIcon}
              alt="SelectIcon"
              className="select-icon sm:rotate-0"
            />
          </div>
        </Box>
        <Box className="child sm:block sm:mt-[12px] sm:scale-y-[1] sm:opacity-100">
          <Box
            className="absolute bg-[#fff] p-[12px] flex flex-col rounded-xl -right-[87px] sm:relative sm:left-0 sm:flex-col sm:space-x-0 sm:p-0 sm:bg-transparent sm:space-y-[12px]"
            sx={{
              boxShadow: isMatchMobile
                ? ""
                : "0px -1px 4px 0px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              backdropFilter: "blur(2px)",
            }}
          >
            <a href="/pusd">
              <Box className="w-[344px]  rounded-xl p-[12px] flex hover:bg-[#F7F7F6] sm:w-full sm:h-auto sm:py-[12px] sm:px-[12px]">
                <Box className="pl-[10px]">
                  <img src={PusdLogo} alt="PusdLogo" />
                </Box>
                <Box className="ml-[8px]">
                  <Typography className="text-[16px] font-medium sm:text-[16px]">
                    PUSD
                  </Typography>
                  <Typography
                    className="text-[13px] mt-[8px] leading-[1.5] sm:mt-[8px]"
                    color="text.secondary"
                  >
                    A fully decentralized, delta-neutral stablecoin built on
                    Ethereum.
                  </Typography>
                </Box>
              </Box>
            </a>
            <a href="/longonly">
              <Box className="w-[344px] mt-[12px] rounded-xl  p-[12px] flex hover:bg-[#F7F7F6] sm:w-full sm:h-auto sm:py-[12px] sm:px-[12px]">
                <Box className="pl-[10px]">
                  <img src={LongOnlyLogo} alt="LongOnlyLogo" />
                </Box>
                <Box className="ml-[8px]">
                  <Typography className="text-[16px] font-medium sm:text-[16px]">
                    LongOnly
                  </Typography>
                  <Typography
                    className="text-[13px] mt-[8px] leading-[1.5] sm:mt-[8px]"
                    color="text.secondary"
                  >
                    An innovative zero-funding-fee perpetual contract protocol.
                  </Typography>
                </Box>
              </Box>
            </a>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <StyledNavLink
      to={to}
      className={({ isActive, isPending }) =>
        `text-base sm:px-0 sm:bg-none ${className} ${
          isPending ? "pending" : isActive ? "active" : ""
        } ${disabled ? "disabled" : ""}`
      }
    >
      {title}
    </StyledNavLink>
  );
};

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  // borderBottom: "2px solid transparent",
  padding: "4px 16px",
  borderRadius: "40px",

  "&:hover": {
    // color: theme.palette.secondary.main,
    background: "#F7F7F6",
  },
  "&.active": {
    background: "#F7F7F6",
  },

  "&.disabled": {
    color: theme.palette.text.disabled,
  },
}));

const StyledALink = styled("a")(({ theme }) => ({
  padding: "4px 16px",
  borderRadius: "40px",

  "&:hover": {
    background: "#F7F7F6",
  },

  "&.active": {
    background: "#F7F7F6",
  },

  "&.disabled": {
    color: "rgba(255, 255, 255, 0.7)",
    pointerEvents: "none",
    cursor: "not-allowed",
  },
}));

export default Navs;
