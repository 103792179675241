import { Chain, configureChains, createClient } from "wagmi";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { SafeConnector } from "wagmi/connectors/safe";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import { sample } from "lodash";
import memoize from "lodash/memoize";

import { SUPPORTED_CHAINS } from "./chains";

export const getPublicNodeURL = (networkName: string) => {
  if (!networkName) return null;
  const currentChainInfo: Chain | undefined = SUPPORTED_CHAINS.find((item) => {
    return item.network === networkName;
  });
  return sample(currentChainInfo?.rpcUrls.public.http);
};

export const getNodeRealUrl = (networkName: string) => {
  const host = getPublicNodeURL(networkName);
  return {
    http: host || "",
    webSocket: host?.replace(/^https/i, "wss"),
  };
};

export const { provider, chains } = configureChains(SUPPORTED_CHAINS, [
  jsonRpcProvider({
    rpc: (chain) => {
      return getNodeRealUrl(chain.network);
    },
  }),
]);

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: true,
  },
});

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: "Equation",
    appLogoUrl: "",
  },
});

// export const walletConnectConnector = new WalletConnectConnector({
// 	chains,
// 	options: {
// 		projectId: '',
// 		metadata: {
// 			name: 'inferface',
// 			description: 'inferface',
// 			url: '',
// 			icons: []
// 		}
// 	}
// });

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: true,
    UNSTABLE_shimOnConnectSelectAccount: true,
  },
});

const safeConnector = new SafeConnector({
  chains,
  options: {
    allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/],
    debug: false,
  },
});

export const client = createClient({
  autoConnect: true,
  connectors: [
    safeConnector,
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    // walletConnectConnector
  ],
  provider,
});

export const CHAIN_IDS = chains.map((c) => c.id);

export const isChainSupported = memoize((chainId: number) =>
  CHAIN_IDS.includes(chainId)
);
export const isChainTestnet = memoize(
  (chainId: number) => chains.find((c) => c.id === chainId)?.testnet
);
